var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"relative overflow-hidden block",class:[
    _vm.bgColorClass,
    _vm.mobileBleed ? '-mx-4' : '',
    _vm.desktopBleed ? 'lg:-mx-14' : 'mx-0',
    _vm.desktopBackgroundOffset ? 'lg:ml-60' : ''
  ],style:({ backgroundColor: _vm.backgroundColorHex })},[(_vm.mobileVideo)?_c('div',{class:[_vm.aspectRatioClass, _vm.desktopVideo.length || _vm.desktopImage.id ? 'lg:aspect-none' : '']},[(_vm.mobileVideo)?_c('video',{ref:"mobileVideo",staticClass:"w-full h-full object-cover",class:[_vm.desktopVideo.length || _vm.desktopImage.id ? 'lg:hidden' : ''],attrs:{"autoplay":"","loop":"","playsinline":"","muted":""},domProps:{"muted":true},on:{"click":function($event){return _vm.contentClick('hero', 'video', _vm.mobileVideo, undefined)}}},[_c('source',{attrs:{"src":_vm.mobileVideo}})]):_vm._e()]):_vm._e(),_vm._v(" "),(_vm.desktopVideo)?_c('div',{staticClass:"hidden lg:block",class:[_vm.aspectRatioClass]},[(_vm.desktopVideo)?_c('video',{ref:"desktopVideo",staticClass:"w-full h-full object-cover",class:{'hidden': _vm.mobileVideo, 'lg:block': _vm.desktopVideo},attrs:{"autoplay":"","loop":"","playsinline":"","muted":""},domProps:{"muted":true},on:{"click":function($event){return _vm.contentClick('hero', 'video', _vm.desktopVideo, undefined)}}},[_c('source',{attrs:{"src":_vm.desktopVideo}})]):_vm._e()]):_vm._e(),_vm._v(" "),(_vm.mobileImage.filename || _vm.desktopImage.filename)?_c('ResponsiveImage',{class:[_vm.mobileVideo ? 'hidden' : '', _vm.desktopVideo ? 'lg:hidden' : '', _vm.mobileVideo && !_vm.desktopVideo ? 'lg:block' : ''],attrs:{"aspect-ratio-class":_vm.aspectRatioClass,"object-position-class":_vm.objectPositionClass,"mobile-src":_vm.mobileSrc,"desktop-src":_vm.desktopSrc,"mobile-display-size":_vm.mobileDisplaySize,"desktop-display-size":_vm.desktopDisplaySize,"alt":_vm.mobileImage.alt ? _vm.mobileImage.alt : '',"img-loading":_vm.imgLoading},on:{"click":function($event){return _vm.contentClick('hero', 'image', _vm.desktopImage.filename, undefined)}}}):_vm._e(),_vm._v(" "),_c('hero-overlay',{staticClass:"z-20",class:[
      _vm.mobileContentPadding ? 'p-' + _vm.mobileContentPadding : '',
      _vm.desktopContentPadding ? 'lg:p-' + _vm.desktopContentPadding : ''
    ],attrs:{"alignment":_vm.alignment,"has-image":(_vm.mobileImage.filename || _vm.desktopImage.filename || _vm.mobileVideo.length || _vm.desktopVideo.length) ? true : false,"inherited-link":_vm.$u(_vm.link) !== null ? _vm.link : null,"tint-value":_vm.tintValue,"gtm-content":_vm.gtmContent}},[_vm._l((_vm.overlayItems),function(block){return [_c(block.component,_vm._b({directives:[{name:"editable",rawName:"v-editable",value:(block),expression:"block"}],key:block.id,tag:"component",attrs:{"inherited-link":_vm.link}},'component',block,false))]})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }