export default {
  name: 'HeroOverlay',

  props: {
    alignment: {
      type: String,
      default: 'middle-left'
    },
    hasImage: {
      type: Boolean,
      default: true
    },
    inheritedLink: {
      type: Object,
      default: () => ({})
    },
    tintValue: {
      type: String,
      default: '0'
    },
    gtmContent: {
      type: Object,
      default: undefined
    }
  },

  computed: {
    textColorClass () {
      return { white: 'text-white', black: 'text-black' }[this.textColor]
    },

    tintValueOutput () {
      return parseInt(this.tintValue) / 100
    },

    flexAlignmentClass () {
      const [y, x] = this.alignment.split('-')

      // TODO: move this to an import, so we can share across components
      const horizontalAlignment = {
        left: 'justify-start',
        center: 'justify-center',
        right: 'justify-end'
      }

      const verticalAlignment = {
        top: 'items-start',
        middle: 'items-center',
        bottom: 'items-end'
      }

      return (
        verticalAlignment[y] +
        ' ' +
        horizontalAlignment[x]
      )
    }
  },
  methods: {
    contentClick () {
      this.$analytics.selectContent('content', 'heroOverlay', this.gtmContent.itemID, this.gtmContent.options)
    }
  }
}
