import HeroOverlay from './HeroOverlay.vue'
export default {
  name: 'Hero',
  components: { HeroOverlay },
  props: {
    mobileImage: {
      type: [Object, String],
      required: false,
      default: null
    },

    mobileVideo: {
      type: [Object, String],
      required: false,
      default: null
    },

    link: {
      type: [Object, String],
      required: false,
      default: null
    },

    desktopImage: {
      type: [Object, String],
      required: false,
      default: null
    },

    desktopVideo: {
      type: [Object, String],
      required: false,
      default: null
    },

    backgroundColor: {
      type: String,
      required: true
    },

    backgroundColorHex: {
      type: String,
      required: false,
      default: ''
    },

    alignment: {
      type: String,
      default: 'middle-left'
    },

    overlayItems: {
      type: Array,
      default: () => []
    },

    mobileBleed: {
      type: Boolean,
      required: false,
      default: false
    },

    desktopBleed: {
      type: Boolean,
      required: false,
      default: false
    },

    objectPosition: {
      type: String,
      default: null
    },

    lgObjectPosition: {
      type: String,
      default: null
    },

    desktopBackgroundOffset: {
      type: Boolean,
      required: false,
      default: false
    },

    tintValue: {
      type: String,
      default: '0'
    },

    mobileContentPadding: {
      type: String,
      default: ''
    },

    desktopContentPadding: {
      type: String,
      default: ''
    },

    mobileDisplaySize: {
      type: String,
      default () {
        return '100vw'
      }
    },

    desktopDisplaySize: {
      type: String,
      default () {
        return '100vw'
      }
    },
    desktopSize: {
      type: String,
      required: true,
      default: 'wide'
    },
    mobileSize: {
      type: String,
      required: true,
      default: 'tall'
    },
    imgLoading: {
      type: String,
      default: 'lazy'
    }
  },
  data () {
    return {
      hlsInstance: null,
      videoEventListenerAdded: false,
    }
  },
  computed: {
    aspectRatioClass () {
      const mobileClasses = {
        tall: 'aspect-w-10 aspect-h-14',
        wide: 'aspect-w-15 aspect-h-10',
        square: 'aspect-w-1 aspect-h-1',
        auto: 'aspect-none'
      }

      // TODO: check repsonsive breakpoints, we might want to offer a tablet option for aspects (and other features)
      const desktopClasses = {
        tall: 'md:aspect-w-10 md:aspect-h-14',
        wide: 'md:aspect-w-15 md:aspect-h-10',
        square: 'md:aspect-w-1 md:aspect-h-1',
        auto: 'md:aspect-none'
      }

      return (
        mobileClasses[this.mobileSize] + ' ' + desktopClasses[this.desktopSize]
      )
    },

    bgColorClass () {
      if (this.backgroundColorHex) {
        return ''
      }

      // TODO: move this to an import, so we can share across components
      const bgColors = {
        transparent: 'bg-transparent',
        white: 'bg-white',
        black: 'bg-black',
        'light-gray': 'bg-light-gray',
        'dark-gray': 'bg-dark-gray',
        'green-gray': 'bg-green-gray',
        red: 'bg-red'
      }

      return bgColors[this.backgroundColor]
    },

    objectPositionClass () {
      const classes = {
        top: 'object-top',
        middle: 'object-middle',
        bottom: 'object-bottom'
      }

      const lgClasses = {
        top: 'lg:object-top',
        middle: 'lg:object-middle',
        bottom: 'lg:object-bottom'
      }

      return `${classes[this.objectPosition]} ${lgClasses[this.lgObjectPosition]}`
    },

    desktopSrc () {
      // return moible image if desktop isnt set to assure image size optimization for both a mobile an desktop
      if (!this.desktopImage?.filename && !!this.mobileImage?.filename) { return this.mobileImage.filename }
      return this.desktopImage.filename
    },

    mobileSrc () {
      // return desktop image if mobile isnt set to assure image size optimization for both a mobile an desktop
      if (!this.mobileImage && !!this.desktopImage) { return this.desktopImage.filename }
      return this.mobileImage.filename
    },

    gtmContent () {
      const result = {
        itemID: this.desktopVideo,
        options: {
          video: this.desktopVideo,
          image: this.desktopImage.filename
        }
      }
      if (this.desktopVideo === null || this.desktopVideo === '') {
        result.itemID = this.desktopImage.filename
      }
      return result
    }
  },
  destroyed () {
    if (this.hlsInstance) {
      try {
        this.hlsInstance.destroy()
      } catch (e) {
        this.$sentryMiddleware.captureInfo('Product Video HLS Destroy', new Error('Product Video HLS Destroy', { cause: e }))
      }
    }
    if (this.videoEventListenerAdded && this.$refs.thevideo) {
      this.$refs.thevideo.removeEventListener('canplay', this.videoCanPlayHandler)
    }
  },
  mounted () {
    if (this.mobileVideo) {
      this.playVideo(this.$refs.mobileVideo, this.mobileVideo)
    }

    if (this.desktopVideo) {
      this.playVideo(this.$refs.desktopVideo, this.desktopVideo)
    }
  },
  methods: {
    videoCanPlayHandler: async function () {
      try {
        await this.$refs.thevideo.play()
      } catch (e) {
        // this.$sentryMiddleware.captureInfo('Hero Video Play', new Error('Hero Video Play', { cause: e }))
      }
    },
    playVideo (video, src) {
      if (!video) { return }
      // Check if HLS is supported
      if (video.canPlayType('application/vnd.apple.mpegurl')) {
        video.src = src
        video.addEventListener('loadedmetadata', this.videoCanPlayHandler)
        this.videoEventListenerAdded = true
      } else if (window.Hls && window.Hls.isSupported()) {
        this.hlsInstance = new window.Hls({
          startLevel: -1, // Ensures maximum quality in the beginning
          capLevelToPlayerSize: true, // Ensures that the video is not unnecessarily scaled up
        })
        try {
          this.hlsInstance.loadSource(src)
          this.hlsInstance.attachMedia(video)
          this.hlsInstance.on(window.Hls.Events.MANIFEST_PARSED, () => {
            video.play()
          })
        } catch (e) {
          // this.$sentryMiddleware.captureInfo('Hero Video HLS Play', new Error('Hero Video HLS Play', { cause: e }))
        }
      } else {
        // If the source is something other than .m3u8, just play the source that was supplied
        this.$sentryMiddleware.captureInfo('Hero Video HLS Support', new Error('Hero Video HLS Support'))
        this.videoCanPlayHandler()
      }
    },

    contentClick (contentType, subContentType, itemID, options) {
      this.$analytics.selectContent(contentType, subContentType, itemID, options)
    }
  }
}
